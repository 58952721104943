<template>
	<div>
		<s-crud
			:config="config"
			add
			@save="save($event)"
			edit
			remove
			title="Pago PreCampaña"
			sortable
		>
			<template scope="props">
				<v-col>
					<v-row>
						<v-col cols="12" class="s-col-form" lg="4" md="6" sm="12" xs="12">
							<s-select-Campaign
								label="Campaña"
								v-model="props.item.CmpID"
							/>
						</v-col>
						<v-col cols="12" class="s-col-form" lg="4" md="6" sm="12" xs="12">
							<sselect-client-sap 
								clearable 
								label="cliente"
								v-model="props.item.PcpCustomerID"
							></sselect-client-sap>
						</v-col>
						<v-col cols="12" class="s-col-form" lg="4" md="6" sm="12" xs="12">
							<s-text
								label="Monto"
								decimal
								v-model="props.item.PcpAmount"
							></s-text>
						</v-col>
					</v-row>
				</v-col>
			</template>
		</s-crud>
	</div>
</template>

<script>
	import _sSlePreCampaignPaymentService from "@/services/Sale/SlePreCampaignPaymentService.js";
	import SselectClientSap from '@/components/Sale/SselectClientSap.vue'

	export default {
		components: {
			SselectClientSap,
		},

		data() {
			return {
				config: {
					service: _sSlePreCampaignPaymentService,
					model: {
						PcpID: "ID",
					},
					headers: [
						{ text: "ID", value: "PcpID" },
						{ text: "Campaña", value: "CmpID" },
						{ text: "Cliente", value: "PcpCustomerName" },
						{ text: "Monto", value: "PcpAmount" }
					],
				},
			}
		},

		methods: {
			save(item) {
				item.save();
			}
		},
	}
</script>