import Service from "../Service";
const resource = "precampaignpayment/";

export default {
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID },
        });
    },

    save(Pcp, requestID) {
        return Service.post(resource + "save", Pcp, {
            params: { requestID }
        });
    },


}